<template>
  <l-tela titulo="Cadastro de Produtos">
    <l-form :salvar="salvar">
      <i-basico
        ref="inputNomeProduto"
        id="nomeProduto"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
      />
      <i-dinheiro
        id="precoProduto"
        label="Preço"
        v-model="form.preco"
        :validations="{ required: true, min: 0 }"
      />
      <template v-slot:botoes>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import produtoService from '@/services/produto.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      form: {
        _id: null,
        nome: '',
        preco: null,
      },
    }
  },
  mounted() {
    const { produtoId } = this.$route.params
    if (produtoId) {
      this.carregar(produtoId)
    } else {
      this.$refs.inputNomeProduto.definirFoco()
    }
  },
  methods: {
    async carregar(produtoId) {
      this.mostrarLoading()
      try {
        const { _id, nome, preco } = await produtoService.buscaPorId(produtoId)
        this.form = { _id, nome, preco }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async salvar() {
      this.mostrarLoading()

      try {
        const { _id, nome, preco } = this.form
        if (_id) {
          await produtoService.atualizar({ _id, nome, preco })
        } else {
          await produtoService.cadastrar({ nome, preco })
          this.sair()
        }
        this.alertaDadosSalvos()
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair() {
      this.$router.push({ name: 'rota-produtos' })
    },
  },
}
</script>
